import React from "react"
import { Router } from "@reach/router"
import OrderResult from '../../components/OrderResult'
import Layout from '../../components/Layout'
import Helmet from "react-helmet"
import Breadcrumb from "../../components/Breadcrumb"
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from "../../hooks/use-site-metadata";
import favicon from "../../../static/favicon.svg";


export default function ResultPage() {
  const { language } = React.useContext(I18nextContext);
  const { title } = useSiteMetadata();

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={favicon} />
        <meta name="title" content={title} />
      </Helmet>
        <Router basepath={`/${language}/checkout/result/`}>
          <Breadcrumb path="/" step={'confirmacion'}/>
        </Router> 
	      <Router basepath={`/${language}/checkout/result/`}>
          <OrderResult path="/"></OrderResult>
        </Router>
    </Layout>
  )
}
