import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { withTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import { VolcanoOrderResultWidget } from "@volcanoteide/vte-components";
import queryString from 'query-string';
import UIkit from 'uikit'

class OrderResult extends Component {

	static contextType = I18nextContext;

	componentDidMount() {
		if (typeof window !== `undefined`) {
			UIkit.container = '.uk-scope';
		}
	}

	render() {
		const { location } = this.props
		const params = queryString.parse(location.search);
		const translations = {
			de: {
			  payment_accepted_title: "Reservierungsdaten",
			  payment_accepted:
				"Die Zahlung wurde akzeptiert. Die Zahlungsbestätigung wird an Ihre E-Mail-Adresse geschickt.",
			  payment_accepted_info: "WICHTIG: Sollte der Wanderweg gesperrt werden, geben wir Ihnen über die E-Mailadresse, die Sie bei Ihrer Reservierung angegeben haben, Bescheid. Damit unsere Hinweise Ihnen korrekt zugestellt werden und nicht im Spam-Ordner landen, empfehlen wir Ihnen, unsere E-Mailadresse notificaciones@caminobarrancodemasca.com in Ihren Kontakten zu speichern.",	
			  payment_denied_title: "Ihre Buchung konnte nicht bestätigt werden",
			  payment_denied: "Ihre Buchung konnte nicht bestätigt werden. Der Zahlungsdienstleister hat Ihre Anfrage abgewiesen und die Zahlung wurde nicht durchgeführt.",
			  payment_free:
				"Ihre Buchung ist bestätigt. Sie erhalten die Buchungsbestätigung per E-Mail.",
			  contact_info:
				'Bei Fragen zu Ihrer Reservierung können Sie uns über die E-Mail-Adresse  <a href="mailto:reservas@caminobarrancodemasca.com"><span class="ow-contact-email">reservas@caminobarrancodemasca.com</span></a> oder die Telefonnummer +34 922 004 552 erreichen',
			  labels: {
				order: "Reservierung für",
				pdf:
				  "Um ein PDF Ihrer Reservierung von <span class=\"ow-booking-pdf-product\">\"<%= product %>\"</span> herunterzuladen, klicken Sie auf diesen Link",
				activity: "Aktivität",
				locator: "Reservierungsnummer",
				invoice: "Um die Rechnung herunterzuladen, klicken Sie auf diesen Link",
			  },
			},
			en: {
			  payment_accepted_title: "Booking Details",
			  payment_accepted:
				"Your payment has been accepted. Booking confirmation will be sent by e-mail.",
			  payment_accepted_info: "IMPORTANT: If the trail is closed, we’ll send an e-mail to the e-mail address you provided when you made your booking. Please save our e-mail address notificaciones@caminobarrancodemasca.com in your e-mail contacts to ensure you receive our messages correctly and to prevent them from being sent to your spam folder.",
			  payment_denied_title: "Reservation not completed",
			  payment_denied: "Reservation not confirmed. The bank has rejected your request and payment has been denied.",
			  payment_free:
				"Reservation confirmed. Booking confirmation will be sent by e-mail.",
			  contact_info:
				'Feel free to contact  us with any questions regarding your purchase by sending an e-mail to <a href="mailto:reservas@caminobarrancodemasca.com"><span class="ow-contact-email">reservas@caminobarrancodemasca.com</span></a> or calling +34 922 004 552',
			  labels: {
				order: "Reservation for",
				pdf:
				  "Click on this link to download a PDF file with your booking for <span class=\"ow-booking-pdf-product\">\"<%= product %>\"</span>",
				activity: "Activity",
				locator: "Booking reference",
				invoice: "To download the invoice, click on this link",
			  },
			},
			fr: {
			  payment_accepted_title: "Détails de la réservation",
			  payment_accepted:
				"Le paiement a été accepté. Le justificatif vous arrivera à travers votre courrier électronique.",
			  payment_accepted_info: "IMPORTANT: If the trail is closed, we’ll send an e-mail to the e-mail address you provided when you made your booking. Please save our e-mail address notificaciones@caminobarrancodemasca.com in your e-mail contacts to ensure you receive our messages correctly and to prevent them from being sent to your spam folder.",
			  payment_denied_title: "Réservation non terminée.",
			  payment_denied: "Réservation non confirmée. Impossible de procéder au paiement.",
			  payment_free:
				"Réservation confirmée. Vous recevrez le reçu par e-mail.",
			  contact_info:
				'Pour toute question concernant votre achat, vous pouvez nous contacter par e-mail <a href="mailto:reservas@caminobarrancodemasca.com"><span class="ow-contact-email">reservas@caminobarrancodemasca.com</span></a> ou par téléphone au +34 999 999 999.',          
			  labels: {
				order: "Réservation pour",
				pdf:
				  "Pour télécharger le PDF contenant votre réservation pour <span class=\"ow-booking-pdf-product\">\"<%= product %>\"</span>, cliquez sur ce lien",
				activity: "Activité",
				locator: "Référence",
				invoice: "Pour télécharger la facture, cliquez sur ce lien",
			  },
			},  
			es: {
			  payment_accepted_title: "Datos de la reserva",
			  payment_accepted:
				"El pago ha sido aceptado, el comprobante llegará a tu correo electrónico",
			  payment_accepted_info: "IMPORTANTE: En caso de cierre del camino, te avisaremos a la dirección de correo electrónico que has facilitado para la reserva. Para recibir correctamente nuestros avisos y evitar que puedan ir a parar a la carpeta de correo no deseado, te recomendamos que guardes nuestra dirección de correo notificaciones@caminobarrancodemasca.com en tu agenda de contactos.",
			  payment_denied_title: "Reserva no completada",
			  payment_denied: "Reserva no confirmada. No ha sido posible efectuar el pago",
			  payment_free:
				"Reserva confirmada. Recibirás el comprobante en tu correo electrónico",
			  contact_info:
				'Cualquier duda en relación con la compra, puedes contactarnos a través del correo <a href="mailto:reservas@caminobarrancodemasca.com"><span class="ow-contact-email">reservas@caminobarrancodemasca.com</span></a> o en el teléfono +34 922 004 552',
			  labels: {
				order: "Reserva para",
				pdf:
				  "Para descargar PDF con tu reserva para <span class=\"ow-booking-pdf-product\">\"<%= product %>\"</span>, pulsa sobre este enlace",
				activity: "Actividad",
				locator: "Localizador",
				invoice: "Para descargar la factura, pulsa sobre este enlace",
			  },
			},
		  };

		return	<div className={`articulo pb-5`}>
					<StaticQuery
						query={graphql`
							query OrderResultVolcanoTeideAPIConfig {
								site {
									siteMetadata {
										volcanoTeideAPIConfig {
											protocol
											host
											port
											strictSSL
											timeout
											site_key
										}
									}
								}
							}
						`}
						render={data => (
							<VolcanoOrderResultWidget 
								apiConfig={data.site.siteMetadata.volcanoTeideAPIConfig}
								locale={this.context.language}
								params={params}
								translations={translations}
							/>
						)}
					/>
				</div>;
	}
}

export default withTranslation('OrderResult')(OrderResult);